import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo"

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  content,
  contentComponent,
  main,
}) => {
    const PageContent = contentComponent || Content;

    return (
        <div>
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div className="has-text-centered hero-header">
                        <h1>{title}</h1>
                    </div>
                    <div className="has-text-centered container notification">
                        <p className="subtitle is-6 has-text-weight-semibold">{main.description1}</p>
                        <p className="subtitle is-6 has-text-weight-semibold"><Link to="/products#programme7">{main.description2}</Link></p>
                        <p className="subtitle is-6 has-text-weight-semibold">{main.description3}</p>
                        <p className="subtitle is-6 has-text-weight-semibold">Wer benutzt gastrokalk? <Link to="/products#referenzen">Siehe Referenzen</Link></p>
                    </div>
                </div>
            </div>
            <div className="container has-text-centered">
                <div className="column">
                    <h3 className="has-text-weight-semibold is-size-4" style={{ marginBottom: "0.5rem" }}>{main.heading}</h3>
                    <h4 className="has-text-weight-semibold is-size-5" style={{ marginBottom: "0.5rem" }}>developed by gastronomes for gastronomes</h4>
                    <p className="item-summary">
                        <div>{main.summary}</div>
                        <div>{main.summary1}</div>
                        <div>{main.summary2}</div>
                        <div>{main.summary3}</div>
                        <div>{main.summary4}</div>
                    </p>
                    <div className="column">
                        <div className="notification is-info has-text-weight-semibold" style={{border: "1px solid #99d5ed", margin: "0.25rem auto",padding: "0.35rem", maxWidth: "31rem"}}><strong>Jetzt bestellen: </strong><b><abbr title="copy und paste email">gastrokalk@gmail.com</abbr></b></div>
                    </div>
                </div>
                <div className="columns hero-bullets">
                    <div className="column">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{main.wasHeading}</h2>
                        <hr />
                        <ul style={{ textAlign: "initial", }}>
                            {main.wasBullets?.map((bullet) => (
                                <li key={bullet}>{bullet}</li>
                            )) || ``}
                        </ul>
                    </div>
                    <div className="column">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{main.kannHeading}</h2>
                        <hr />
                        <ul style={{ textAlign: "initial", }}>
                            {main.kannBullets?.map((bullet) => (
                                <li key={bullet}>{bullet}</li>
                            )) || ``}
                        </ul>
                    </div>
                </div>
                <div className="column is-full" dangerouslySetInnerHTML={{ __html: main.video || ``, }} />
                <PageContent content={content} />
                <p style={{ padding: "2rem 0" }}>
                    <Link className="button is-success" to="/products">
                        gastrokalk© Programme
                    </Link>
                </p>
                <div className="tile is-ancestor sk-images">
                    <div className="tile is-vertical">
                        <div className="tile">
                            <div className="tile is-parent is-vertical">
                                <article className="tile is-child">
                                    <PreviewCompatibleImage imageInfo={main.image1} />
                                </article>
                            </div>
                            <div className="tile is-parent">
                                <article className="tile is-child">
                                    <PreviewCompatibleImage imageInfo={main.image2} />
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-ancestor sk-images">
                    <div className="tile is-vertical">
                        <div className="tile">
                            <div className="tile is-parent is-vertical">
                                <article className="tile is-child">
                                    <PreviewCompatibleImage imageInfo={main.image3} />
                                </article>
                            </div>
                            <div className="tile is-parent">
                                <article className="tile is-child">
                                    <PreviewCompatibleImage imageInfo={main.image4} />
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/* container */}
        </div>
    );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  main: PropTypes.shape({
    heading: PropTypes.string,
    description1: PropTypes.string,
    description2: PropTypes.string,
    description3: PropTypes.string,
    summary: PropTypes.string,
    summary1: PropTypes.string,
    summary2: PropTypes.string,
    summary3: PropTypes.string,
    summary4: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    wasHeader: PropTypes.string,
    wasBullets: PropTypes.string,
    kannHeader: PropTypes.string,
    kannBullets: PropTypes.string,
    warumHeader: PropTypes.string,
    warumBullets: PropTypes.string,
    video: PropTypes.string,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        intro={frontmatter.intro}
        image={frontmatter.image}
        title={frontmatter.title}
        main={frontmatter.main}
        contentComponent={HTMLContent}
        content={data.markdownRemark.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
        content: PropTypes.string,
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        main {
          heading
          description1
          description2
          description3
          summary
          summary1
          summary2
          summary3
          summary4
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 350, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 350, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 350, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          image4 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 350, quality: 92, layout: CONSTRAINED)
              }
            }
          }
          wasHeading
          wasBullets
          kannHeading
          kannBullets
          warumHeading
          warumBullets
          video
        }

      }
    }
  }
`;
